<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center">
          <span class="text-h2">404 - Not found</span>
        </v-row>
        <v-row justify="center"
          >La page que vous cherchez se trouve dans une autre vitrine !</v-row
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({})
}
</script>
